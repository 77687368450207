const rbacRules = {
  KM_NO_RIGHTS: {
    static: [
    ]
  },
  KM_STUDENT: {
    static: [
      'event:create',
      'layout/Dashboard/Dashboard:visit',
      
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/Resources/resource:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'views/KMResourceDetailView/KMResourceDetailView:visit',
      'association:create',
      'associationDetail:save',
      'event:duplicate',
      'resource:download',
    ],
    dynamic: {
      'eventDetail:cancel': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:comment': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'event:archive': ({ userId, eventOwnerId, eventFlagValidationAskedByUser }) => {
        if (!userId || !eventOwnerId) return false
        if (userId !== eventOwnerId)
          return false
        if (!!eventFlagValidationAskedByUser === true) return false
        return true
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'views/KMProfilDetailView/KMProfilDetailView:visit': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_CHARGEE_DE_MISSION_VIE_ETUDIANTE: {
    static: [
      'views/KMReportListView/KMReportListView:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/KMReportDetailView/KMReportDetailView:visit',
      'views/KMAssociationListView/KMAssociationListView:visit',
      'views/KMAssociationDetailView/KMAssociationDetailView:visit',
      'views/KMAssociationListView/KMAssociationListView:visit',
      'views/KMAssociationDetailView/KMAssociationDetailView:visit',
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'event:create',
      'eventDetail:validN1',
      'eventDetail:comment',
      'eventDetail:refuse',
      'eventDetail:valid',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_RESPONSABLE_DE_VIE_ASSOCIATIVE: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'views/KMResourceDetailView/KMResourceDetailView:visit',
      'eventDetail:validN1',
      'eventDetail:comment',
      'eventDetail:refuse',
      'eventDetail:valid',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_SERVICE_LOGISTIC: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'eventDetail:validN2',
      'eventDetail:comment',
      'eventDetail:refuse',
      'eventDetail:valid',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_SERVICE_MULTIMEDIA: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'eventDetail:validN2',
      'eventDetail:comment',
      'eventDetail:refuse',
      'eventDetail:valid',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_SERVICE_TECHNIC: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'eventDetail:validN2',
      'eventDetail:comment',
      'eventDetail:refuse',
      'eventDetail:valid',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_SERVICE_DMG: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'eventDetail:refuse',
      // 'eventDetail:validN2',
      'event:create',
      'event:archive',
      'eventDetail:comment',
      'eventDetail:validN2',
      'eventDetail:valid',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
      // 'location:archive',
      // 'location:create',
      // // 'demo',
      // 'views/KMLocationView/KMLocationView:visit',
      'views/KMMessageListView/KMMessageListView:visit',
      'views/KMMessageDetailView/KMMessageDetailView:visit',
      'message:archive',
      'message:create',
      'message:download',
      'messageDetailRight:change',
      'messageDetail:save',
      'views/KMReportListView/KMReportListView:visit',
      'views/KMReportDetailView/KMReportDetailView:visit',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_SERVICE_SURVEILLANCE: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      // 'eventDetail:validN2',
      'event:create',
      'event:archive',
      'eventDetail:comment',
      'eventDetail:validN2',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_SERVICE_SECURITY: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      // 'eventDetail:validN2',
      'eventDetail:comment',
      'eventDetail:validN2',
      'eventDetail:commentPublicPrivate',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'views/Resources/resource:visit',
      'association:create',
      'associationDetail:save',
      'resource:download',
    ],
    dynamic: {
      'resourceDetailRight:change': ({ userId, resourceStoreCreateBy }) => {
        if (!userId || !resourceStoreCreateBy) return false
        return userId === resourceStoreCreateBy
      },
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_DIRECTEUR_DE_CABINET:{
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardSession:visit',
      'views/stats/userAwaitingForAccreditation',
      'views/stats/userStatsNbAll',
      'views/stats/stockageStatsWeight',
      'views/UserProfile/UserProfile:visit',
      // 'views/NotificationsPage/NotificationsPage:visit',
      // 'views/ApiListView/ApiListView:visit',
      'views/KMUserManagementView/KMUserManagementView:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KMAssociationListView/KMAssociationListView:visit',
      'views/KMAssociationDetailView/KMAssociationDetailView:visit',
      'views/KMMessageListView/KMMessageListView:visit',
      'views/KMMessageDetailView/KMMessageDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'views/KMResourceDetailView/KMResourceDetailView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMListEstablishmentView/KMListEstablishmentView:visit',
      'views/KMEstablishmentDetailView/KMEstablishmentDetailView:visit',
      'views/KMReportListView/KMReportListView:visit',
      'views/KMReportDetailView/KMReportDetailView:visit',
      'event:createPDF',
      'eventDetail:comment',
      'eventDetail:eventFlagPublishInWeb',
      'eventDetail:commentPublicPrivate',
      'message:download',
      'resource:download',
      'userDetail:expirePassword',
      'userDetail:history',
      // 'demo',
      'views/KMLocationView/KMLocationView:visit',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
    ],
    dynamic: {
    
    }
  },
  KM_SUPERVISOR: {
    static: [
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardMain:visit',
      'views/Dashboard/Dashboard:visit',
      'views/Dashboard/DashboardSession:visit',
      'views/stats/userAwaitingForAccreditation',
      'views/stats/userStatsNbAll',
      'views/stats/stockageStatsWeight',
      'views/UserProfile/UserProfile:visit',
      // 'views/NotificationsPage/NotificationsPage:visit',
      // 'views/ApiListView/ApiListView:visit',
      'views/KMUserManagementView/KMUserManagementView:visit',
      'views/KMListEventView/KMListEventView:visit',
      'views/KMAssociationListView/KMAssociationListView:visit',
      'views/KMAssociationDetailView/KMAssociationDetailView:visit',
      'views/KMMessageListView/KMMessageListView:visit',
      'views/KMMessageDetailView/KMMessageDetailView:visit',
      'views/KMResourceListView/KMResourceListView:visit',
      'views/KMResourceDetailView/KMResourceDetailView:visit',
      'views/KmEventDetailView/KmEventDetailView:visit',
      'views/KMListEstablishmentView/KMListEstablishmentView:visit',
      'views/KMEstablishmentDetailView/KMEstablishmentDetailView:visit',
      'views/KMScheduledBroadcastListView/KMScheduledBroadcastListView:visit',
      'views/KMScheduledBroadcastDetailView/KMScheduledBroadcastDetailView:visit',
      'views/KMReportListView/KMReportListView:visit',
      'views/KMReportDetailView/KMReportDetailView:visit',
      'views/KMProductListView/KMProductListView:visit',
      'views/KMProductDetailView/KMProductDetailView:visit',
      'establishmentDetail:save',
      'establishment:archive',
      'event:create',
      'event:archive',
      'event:duplicate',
      'event:createPDF',
      'eventDetail:comment',
      'eventDetail:refuse',
      'eventDetail:valid',
      'eventDetail:validN1',
      'eventDetail:validN2',
      'eventDetail:validN3',
      'eventDetail:eventFlagPublishInWeb',
      'eventDetail:commentPublicPrivate',
      'message:archive',
      'message:create',
      'message:download',
      'messageDetailRight:change',
      'messageDetail:save',
      'resource:archive',
      'resource:create',
      'resource:download',
      'resourceDetailRight:change',
      'resourceDetail:save',
      'scheduledBroadcast:archive',
      'scheduledBroadcast:unArchive',
      'scheduledBroadcastDetailSegment:change',
      'scheduledBroadcast:activate',
      'scheduledBroadcast:desactivate',
      'scheduledBroadcast:create',
      'scheduledBroadcast:download',
      'scheduledBroadcastDetail:save',
      'association:archive',
      'association:create',
      'associationDetail:save',
      'user:create',
      'location:archive',
      'location:create',
      'userDetail:save',
      'userDetail:delete',
      'userDetail:expirePassword',
      'userDetail:history',
      // 'demo',
      'views/KMLocationView/KMLocationView:visit',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'KMeventAssociation:viewPresident',
      'eventDetail:cancel',
      'eventDetail:delete',
      'product:create',
      'productDetail:save',
      'product:archive',
      'profil:changePole',
    ],
    dynamic: {
      'eventDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'eventDetail:send': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  writer: {
    static: [
    ],
    dynamic: {
      'posts:edit': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false
        return userId === postOwnerId
      }
    }
  },
  admin: {
    static: [
      'layout/Dashboard/Dashboard:visit'
    ]
  }
}

export default rbacRules
